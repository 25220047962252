import Vue from 'vue'
import App from './App.vue'
import VueCountdownTimer from 'vuejs-countdown-timer';

Vue.config.productionTip = false
Vue.use(VueCountdownTimer);

new Vue({
  render: h => h(App),
}).$mount('#app')
