<template>
  <div class="countdown">
    <div class="block">
      <p class="digit">{{ days | two_digits }}</p>
      <p class="text">Jours</p>
    </div>

    <div class="block">
      <p class="digit">{{ hours | two_digits }}</p>
      <p class="text">Heures</p>
    </div>

    <div class="block">
      <p class="digit">{{ minutes | two_digits }}</p>
      <p class="text">Minutes</p>
    </div>

    <div class="block">
      <p class="digit">{{ seconds | two_digits }}</p>
      <p class="text">Secondes</p>
    </div>
  </div>
</template>

<style lang="scss">
.countdown {
  width: 60%;

  @media (max-width: $breakpoint-mobile) {
    width: 80%;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.digit {
  font-size: 50px;

  @media (max-width: $breakpoint-mobile) {
    font-size: 30px;
  }
}

.text {
  @media (max-width: $breakpoint-mobile) {
    font-size: 15px;
  }
}
</style>
<script>

export default {
  name: 'Countdown',
  mounted() {
    window.setInterval(() => {
      this.now = Math.trunc((new Date()).getTime() / 1000);
    },1000);
  },
  props: {
    date: {
      type: String
    }
  },
  data() {
    return {
      now: Math.trunc((new Date()).getTime() / 1000)
    }
  },
  computed: {
    dateInMilliseconds() {
      return Math.trunc(Date.parse(this.date) / 1000)
    },
    seconds() {
      return (this.dateInMilliseconds - this.now) % 60;
    },
    minutes() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
    },
    hours() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
    },
    days() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
    }
  },
  filters: {
    two_digits: function (value) {
      if (value < 0) {
        return '00';
      }
      if (value.toString().length <= 1) {
        return `0${value}`;
      }
      return value;
    }
  }
}
</script>