<template>
  <div>
    <div class="c-Details-Wrapper">
      <div class="c-Details-Wrapper-Content">
        <div class="c-Details-Wrapper-Content-Left">
          <span class="c-Details-Wrapper-Content-Left-Title">Télécharger le programme de la journée</span>
          <a href="faire_part_verso.pdf" target="_blank" class="c-Btn">Télécharger</a>
        </div>
        <div class="c-Details-Wrapper-Content-Right">
          <span>Merci de nous répondre avant le 16 mai 2021 par coupon réponse ou par email à l'adresse mail suivante :</span>
          <span class="c-Details-Wrapper-Content-Right-Mail">
            <a href="mailto:remi.dup73@gmail.com">remi.dup73@gmail.com</a>
          </span>

          <a href="rsvp.pdf" target="_blank" class="c-Btn">Télécharger</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  }
}
</script>


<style lang="scss">
:root {
  --width: 70vw;
  --full-width: 70vw;
  --angle: -5deg;
  /*-----------------
  Magic Number Alert:

  the 0.09719 is calculated by this formula:
  tan(11°) / 2
  11° is the current skewY value.
  ------------------*/
  --magic-number: 0.043744;
  --skew-padding: calc(var(--width) * var(--magic-number));
  --clip-padding: calc(var(--full-width) * var(--magic-number));
}

a {
  text-decoration: none;
  color: inherit;
}

.c-Btn {
  background-color: #8F6314;
  text-decoration: none;
  color: black;
  padding: 20px 40px;
  border-radius: 5px;
  margin: 20px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition: ease 0.5s;

  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
}

.c-Details-Wrapper {
  position: relative;
  padding: var(--skew-padding) 0;
  margin-top: -20px;

  &:before {
    background-color: #86988E;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: skewy(var(--angle));
    transform-origin: 50% 0;
    outline: 1px solid transparent;
    backface-visibility: hidden;
  }

  &-Content {
    font-family: 'Playfair Display', 'serif';
    max-width: var(--width);
    margin: 0 auto;
    padding: 100px 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }

    &-Left {
      flex: 1;


      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 100px;
      }

      &-Title {
        display: block;
        margin-bottom: 40px;
      }
    }

    &-Right {
      flex: 1;

      &-Mail {
        margin-bottom: 40px;
      }

      span {
        display: block;
        //white-space: nowrap;
      }
    }
  }
}
</style>