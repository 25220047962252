<template>
  <div>
    <div class="c-Header-Wrapper">
      <img class="c-Header-Wrapper-Img" src="../assets/main_image.png" alt="">
      <div class="c-Header-Wrapper-Right">
        <span class="c-Header-Wrapper-Right-Title">Save the date</span>
        <div class="c-Header-Wrapper-Right-Date">
          <span class="c-Header-Wrapper-Right-Date-First c-Text-Minor">Jennifer et Rémi sont heureux de vous inviter à leur mariage le</span>
          <span class="c-Header-Wrapper-Right-Date-Second">07 Aout 2021</span>
          <span class="c-Header-Wrapper-Right-Date-Third c-Text-Minor">à Saint Alban-Leysse.</span>
          <img class="c-Header-Wrapper-Right-Date-Arrow" src="../assets/arrow.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  }
}
</script>


<style lang="scss">
.c-Text-Minor {
  font-family: 'Blacker Display', 'serif';
  font-size: 25px;

  @media (max-width: $breakpoint-mobile) {
    font-size: 15px;
  }

}
.c-Header-Wrapper {
  font-family: "Amalfi Coast";
  flex-direction: row;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #8F6314;


  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }

  &-Img {
    padding: 20px 20px;
    width: 50%;

    @media (max-width: $breakpoint-mobile) {
      width: 100%;
      align-self: center;
      padding-left: 40px;
    }
  }

  &-Right {
    width: 100%;
    background-color: #E4F3E3;

    &-Title {
      padding: 20px 20px;
      margin: 0;
      text-align: center;
      font-size: 5vw;
      width: 100%;

      @media (max-width: $breakpoint-mobile) {
        font-size: 40px;
      }
    }

    &-Date {
      text-align: center;
      color: #343a40;
      font-size: 40px;
      padding: 50px 20px 0 20px;

      span {
        color: #49514E;
        display: block;
        width: 100%;
      }

      &-Second {
        font-size: 30px;
        margin-bottom: 25px;
        @media (max-width: $breakpoint-mobile) {
          padding-top: 20px;
          font-size: 20px;
        }
      }


      &-Arrow {
        padding: 70px 0 100px 0;
        width: 50%;

        @media (max-width: $breakpoint-mobile) {
          padding: 70px 0 0 0;
        }
      }
    }
  }
}
</style>