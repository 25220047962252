<template>
  <div id="app">
    <Header/>
    <DetailsFile/>
    <Counter/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import DetailsFile from './components/DetailsFile.vue'
import Counter from './components/Counter.vue'

export default {
  name: 'App',
  components: {
    Header,
    DetailsFile,
    Counter
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

html,body{
  overflow-x: hidden;
}

html {
  font-size: 100%;
}

body{
  width:100%;
  overflow-x:hidden;
  overflow-y:hidden;
}

@font-face {
  font-family: "Amalfi Coast";
  src: url(./assets/fonts/AmalfiCoast.ttf) format("truetype");
}

@font-face {
  font-family: "Blacker Display";
  src: url(./assets/fonts/Blacker-Display-ExtraBold-trial.ttf) format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}

</style>
