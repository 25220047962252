<template>
  <div>
    <div class="c-Counter-Wrapper">
      <div class="c-Counter-Wrapper-Content">
        <countdown date="2021-08-07"></countdown>
      </div>
    </div>
  </div>
</template>

<script>
import Countdown from './Countdown';

export default {
  name: 'Counter',
  components: {
    Countdown
  }
}
</script>


<style lang="scss">
:root {
  --width: 70vw;
  --full-width: 70vw;
  --angle: -5deg;
  /*-----------------
  Magic Number Alert:

  the 0.09719 is calculated by this formula:
  tan(11°) / 2
  11° is the current skewY value.
  ------------------*/
  --magic-number: 0.043744;
  --skew-padding: calc(var(--width) * var(--magic-number));
  --clip-padding: calc(var(--full-width) * var(--magic-number));
}

.c-Counter-Wrapper {
  position: relative;
  padding: var(--skew-padding) 0;
  margin-top: -1px;


  &:before {
    background-color: white;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: skewy(var(--angle));
    transform-origin: 50% 0;
    outline: 1px solid transparent;
    backface-visibility: hidden;
  }

  &-Content {
    font-family: 'Playfair Display', 'serif';
    max-width: 90%;
    margin: 0 auto;
    padding: 50px 0 0 0;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
</style>